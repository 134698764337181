/* Google fonts Pacifico */
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500;700&display=swap');

/* Billboard */
.parallax-bg {
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.top {
  height: calc(100vh - 64px);
}

.pacific {
  font-family: 'Pacifico', 'M PLUS Rounded 1c', sans-serif;
}
.pacific-rotate {
  font-family: 'Pacifico', 'M PLUS Rounded 1c', sans-serif;
  font-weight: 500 !important;
  line-height: 1.05;
  transform: rotate(-5deg);
}
.big-text {
  font-size: 5rem;
  font-weight: normal;
}
.semi-big-text {
  font-size: 2rem;
  font-weight: normal;
  margin-top: 2rem;
}
.txt-center {
  text-align: center;
}
.c-title {
  background: #fff;
  background: var(--theme-content-section-background-color);
  border-bottom: 1px solid #ddd;
  padding: 1rem;
}
.center-text-box {
  max-width: 720px;
  text-align: left;
  margin: 1rem auto;
}
.c-card__info {
  padding: 16px;
}

.c-card__info__ttl {
  height: 50px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.35;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.c-card__info__desc {
  height: 42px;
  font-size: 14px;
  line-height: 1.5;
  color: #555555;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-top: 8px;
}

.c-card__info__week {
  height: 18px;
  font-size: 12px;
  line-height: 1.5;
  color: #aaa;
  margin-top: 8px;
}
.c-card__info__week span {
  display: inline-block;
  background: #0094cc;
  background: var(--theme-primary-color);
  color: #fff;
  font-weight: bold;
  padding: 0.125rem 0.3rem;
  border-radius: 12px;
}
.c-card__info__week span.close {
  background: #ccc;
}

.c-card__info__bottom {
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.c-card__info__bottom__place {
  width: calc(100% - 140px);
  font-size: 14px;
  font-weight: bold;
  color: #ffcc00;
  color: var(--theme-secondary-color);
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.c-card__info__bottom__plice {
  width: 140px;
  font-size: 18px;
  font-weight: bold;
  text-align: right;
}

@media screen and (max-width: 767px) {
  .top {
    height: calc(100vh - 56px);
  }

  /* Billboard */
  .big-text {
    font-size: 3rem;
    font-weight: normal;
  }
  /* /Billboard */

  .center-text-box {
    max-width: 350px;
  }
}

/* For touchscreens, scroll image since iOS doesn't recognize "background-attachment: fixed;" */
@media (hover: none) {
  .parallax-bg {
    background-attachment: scroll;
  }
}
